import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
};

export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);
  const [successMessage, setSuccessMessage] = useState(""); // New state for success message

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
        .sendForm("service_15ig8kf", "template_Maopk3Zz", e.target, "UL8L0F5bcitpSi1en")
        .then(
            (result) => {
              console.log(result.text);
              setSuccessMessage("Message sent successfully!"); // Set success message
              clearState();
            },
            (error) => {
              console.log(error.text);
              setSuccessMessage("Failed to send the message. Please try again.");
            }
        );

    e.target.reset();

    // Automatically clear success message after 5 seconds
    setTimeout(() => {
      setSuccessMessage("");
    }, 5000);
  };

  return (
      <div>
        <div id="contact">
          <div className="container">
            <div className="col-md-8">
              <div className="row">
                <div className="section-title">
                  <h2>Get In Touch</h2>
                  <p>
                    Please fill out the form below to send us an email and we will
                    get back to you as soon as possible.
                  </p>
                </div>

                {successMessage && ( // Display the success message
                    <div className="alert alert-success" role="alert">
                      {successMessage}
                    </div>
                )}

                <form name="sentMessage" validate onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                            type="text"
                            id="name"
                            name="name"
                            className="form-control"
                            placeholder="Name"
                            required
                            value={name} // Bind to state
                            onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder="Email"
                            required
                            value={email} // Bind to state
                            onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                  <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="Message"
                      required
                      value={message} // Bind to state
                      onChange={handleChange}
                  ></textarea>
                  </div>
                  <button type="submit" className="btn btn-custom btn-lg">
                    Send Message
                  </button>
                </form>
              </div>
            </div>
            <div className="col-md-3 col-md-offset-1 contact-info">
              <div className="contact-item">
                <h3>Contact Info</h3>
                <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                  {props.data ? props.data.address : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                  {props.data ? props.data.email : "loading"}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="footer">
          <div className="container text-center">
            <p>&copy; Copyright 2024 Auturo</p>
          </div>
        </div>
      </div>
  );
};
